<template>
  <detail-container :has-header="false" pageTitle="대출 상세" :is-page-loaded="isLoadedData">
    <div v-html="html"></div>
  </detail-container>
</template>

<script>
import { isIOS } from "mobile-device-detect";
import { getInAppTemplate } from "@/api";
import { ROUTES_NAME } from "@/const/router";
import { uiActions, userActions, userGetters } from "@/store";
import DetailContainer from "@/components/layout/DetailContainer";
import { gaTracker, traceEvent } from "@/helpers/tracer";
import { move } from "@/helpers/move";
import { isResSuccess } from "@/helpers";

export default {
  name: ROUTES_NAME.LOAN_TEMPLATE,
  components: {
    DetailContainer
  },
  data: () => ({
    initialize: false, // to call only once
    isLoadedData: false,
    html: ""
  }),
  computed: {
    ...userGetters(["trackCdType", "userInfo", "isInitialUser"])
  },
  watch: {
    userInfo: {
      immediate: true,
      async handler(v) {
        // do not use isLoadedData
        if (Object.keys(v).length > 0 && !this.initialize) {
          this.initialize = true;

          // EBMP 미가입자인 경우 약관 전체 미동의로 우리쪽에 데이터를 쌓는다 (PUSH 등으로 바로 들어온 경우 대비)
          // 반드시 getData 보다 먼저 와야 한다. 로깅을 적절히 해야하고, 신규 페이지에서 바로 재차 신규 생성 요청이 들어갈 수 있기 때문에
          if (this.isInitialUser) {
            await this.setAgreeInfo({
              agreeInfoForm: {
                trdInfo: false
              }
            });
          }

          await this.getData();

          //this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.TEMPLATE, common_code: this.summary.compNm } });
        }
      }
    }
  },
  methods: {
    ...userActions(["setAgreeInfo"]),
    ...uiActions(["setBridgeDialog"]),
    onClickCardButton(e) {
      const { gp_mapp_seq, ads_seq, pay_yn } = this.$route.query;
      const itSeq = this.$route.params.itSeq;

      const { actionCode, linkType, url } = e.target.dataset;
      // actionCode - 번호코드 : A005 ~ A010
      // linkType -  I: 인앱, C: 외부 브라우저
      // url - 링크

      move({ destinationType: linkType, destinationUrl: url });

      // subSeq 안쓰기로 함.
      this.wrapTraceEvent(
        {
          gpMappSeq: gp_mapp_seq,
          adsSeq: ads_seq,
          payYn: pay_yn,
          itSeq
        },
        actionCode
      );

      gaTracker({
        category: `대출 브릿지 > 버튼`,
        action: `[${actionCode}] 클릭`,
        label: `${this.trackCdType}`,
        value: ads_seq
      });
    },
    wrapTraceEvent({ gpMappSeq, adsSeq, payYn, subAdsSeq, itSeq }, eventType) {
      traceEvent({
        eventType,
        gpMappSeq,
        adsSeq,
        subAdsSeq,
        payYn,
        itSeq
      });
    },
    displayedPageTracer() {
      const { gp_mapp_seq, ads_seq, pay_yn, from_push } = this.$route.query; // WARN. insu_seq가 필수임.
      const itSeq = this.$route.params.itSeq;

      if (from_push) {
        this.wrapTraceEvent(
          {
            gpMappSeq: gp_mapp_seq,
            adsSeq: ads_seq,
            payYn: pay_yn,
            itSeq
          },
          "I008" // 대출 템플릿 상세(대출 프로모션 상세와 동일 코드)
        );
      }
      this.wrapTraceEvent(
        {
          gpMappSeq: gp_mapp_seq,
          adsSeq: ads_seq,
          payYn: pay_yn,
          itSeq
        },
        "I002" // 상세 유입코드
      );
    },
    async getData() {
      const { itSeq } = this.$route.params;

      this.isLoadedData = false;
      if (itSeq) {
        const res = await getInAppTemplate({ condition: { itSeq } });
        if (isResSuccess(res) && res.data.result) {
          const item = res.data.result.item;
          this.html = item.parsedHtml;
        }

        if (isIOS) {
          window.addEventListener("touchstart", (e) => {
            if (e.target.classList && e.target.classList[0] && e.target.classList[0].includes("bridge-button")) {
              this.onClickCardButton(e);
            }
          });
        } else {
          window.addEventListener("click", (e) => {
            if (e.target.classList && e.target.classList[0] && e.target.classList[0].includes("bridge-button")) {
              this.onClickCardButton(e);
            }
          });
        }

        this.isLoadedData = true;
        this.displayedPageTracer();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.bridge-button {
  cursor: pointer;
}
</style>
